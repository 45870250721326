import * as React from "react"
import { Link } from "gatsby"
import Layout from "../layout"
import PostalAddress from "../components/address"

// markup
const PrivacyPage = () => {
  return (
    <Layout>
      <title>Privacy</title>
      <p class="prompt prompt__privacy" id="cat"></p>

      <Link to="/" aria-label="Back to home" className="tohome">&larr; Back to home</Link>

			<section className="content">
				<h1>Datenschutzerkl&auml;rung</h1>
        <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
        <PostalAddress founder="Tim Isenheim" />
        <h2>Ihre Betroffenenrechte</h2>
        <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten k&ouml;nnen Sie jederzeit folgende Rechte aus&uuml;ben:</p>
        <ul>
        <li>Auskunft &uuml;ber Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
        <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
        <li>L&ouml;schung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
        <li>Einschr&auml;nkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht l&ouml;schen d&uuml;rfen (Art. 18 DSGVO),</li>
        <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
        <li>Daten&uuml;bertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
        </ul>
        <p>Sofern Sie uns eine Einwilligung erteilt haben, k&ouml;nnen Sie diese jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen.</p>
        <p>Sie k&ouml;nnen sich jederzeit mit einer Beschwerde an eine Aufsichtsbeh&ouml;rde wenden, z. B. an die zust&auml;ndige Aufsichtsbeh&ouml;rde des Bundeslands Ihres Wohnsitzes oder an die f&uuml;r uns als verantwortliche Stelle zust&auml;ndige Beh&ouml;rde.</p>
        <p>Eine Liste der Aufsichtsbeh&ouml;rden (f&uuml;r den nicht&ouml;ffentlichen Bereich) mit Anschrift finden Sie unter: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="noreferrer nofollow noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
        <p></p><h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen &uuml;bermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und &auml;hnliches. </p>
        <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
        <ul>
        <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
        <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
        <li>Auswertung der Systemsicherheit und -stabilit&auml;t sowie</li>
        <li>zur Optimierung unserer Website.</li>
        </ul>
        <p>Wir verwenden Ihre Daten nicht, um R&uuml;ckschl&uuml;sse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren. </p>
        <h3>Rechtsgrundlage und berechtigtes Interesse:</h3>
        <p>Die Verarbeitung erfolgt gem&auml;ß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer Website.</p>
        <h3>Empf&auml;nger:</h3>
        <p>Empf&auml;nger der Daten sind ggf. technische Dienstleister, die f&uuml;r den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter t&auml;tig werden.</p>
        <p></p><h3>Speicherdauer:</h3>
        <p>Die Daten werden gel&ouml;scht, sobald diese f&uuml;r den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist f&uuml;r die Daten, die der Bereitstellung der Website dienen, grunds&auml;tzlich der Fall, wenn die jeweilige Sitzung beendet ist. </p>
        <p> Im Falle der Speicherung der Daten in Logfiles ist dies nach sp&auml;testens 14 Tagen der Fall. Eine dar&uuml;berhinausgehende Speicherung ist m&ouml;glich. In diesem Fall werden die IP-Adressen der Nutzer anonymisiert, sodass eine  Zuordnung  des aufrufenden Clients nicht mehr m&ouml;glich ist.</p>
        <p></p><h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsf&auml;higkeit unserer Website nicht gew&auml;hrleistet. Zudem k&ouml;nnen einzelne Dienste und Services nicht verf&uuml;gbar oder eingeschr&auml;nkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen. </p>
        <p></p><h2>SSL-Verschl&uuml;sselung</h2>
        <p>Um die Sicherheit Ihrer Daten bei der &Uuml;bertragung zu sch&uuml;tzen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschl&uuml;sselungsverfahren (z. B. SSL) &uuml;ber HTTPS.</p>
        <p></p><hr />
        <h2>Information &uuml;ber Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
        <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
        <p>Sie haben das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabw&auml;gung) erfolgt, Widerspruch einzulegen; dies gilt auch f&uuml;r ein auf diese Bestimmung gest&uuml;tztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
        <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</p>
        <h3>Empf&auml;nger eines Widerspruchs</h3>
        <p>Tim Isenheim</p>
        <hr />
        <h2>&Auml;nderung unserer Datenschutzbestimmungen</h2>
        <p>Wir behalten uns vor, diese Datenschutzerkl&auml;rung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um &Auml;nderungen unserer Leistungen in der Datenschutzerkl&auml;rung umzusetzen, z.B. bei der Einf&uuml;hrung neuer Services. F&uuml;r Ihren erneuten Besuch gilt dann die neue Datenschutzerkl&auml;rung.</p>
        <h2>Fragen an den Datenschutzbeauftragten</h2>
        <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die f&uuml;r den Datenschutz verantwortliche Person in unserer Organisation:</p>
        <p>tim.isenheim@freshlabs.de</p>
        <p><em>Die Datenschutzerkl&auml;rung wurde mithilfe der activeMind AG erstellt, den Experten f&uuml;r <a href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank" rel="noreferrer noopener">externe Datenschutzbeauftragte</a> (Version #2020-09-30).</em></p>

      </section>
    </Layout>
  )
}

export default PrivacyPage
